import { raygun } from '@ynap/clientside-monitoring';

//TODO
// const getHero = () => {
//     return new Promise((resolve, reject) => {
//         const observer = new PerformanceObserver(list => {
//             for (const entry of list.getEntries()) {
//                 if (entry.name === 'hero') {
//                     resolve({ custom5: entry.startTime });
//                 }
//             }
//         });
//         observer.observe({ entryTypes: ['mark'] });
//     });
// };
// raygun.agent.footerScript([getHero()]);

raygun.agent.footerScript();
